<template>
  <div >
    <div class="top-header d-lg-block d-none">
      <div class="container-fluid" style="width: 100%; margin: 0;">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="top-header-infor">
            </div>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4 top-header-users">
            <div class="top-header-user">
              <ul>
                <li class="dropdown-language">
                  <i class="bi bi-globe-americas"></i>
                  <div>
                    <div class="dropdown-toggle">
                      Language
                    </div>
                  </div>
                </li>
                <li class="login-item">
                  <i class="bi bi-person-circle"></i>
                  <router-link v-if="user" :to="{ name: 'dashboard' }" id="user-link">{{ user }}</router-link>
                  <router-link v-else to="/login" id="login">{{ 'Login' }}</router-link>
                </li>
                <li>
                  <i class="bi bi-envelope-fill"></i>
                  <a href="#">Message</a> 
                </li>
              </ul>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <div class="top-navList">
      <div class="row ">
        <div class="original-box d-lg-block d-none">
          <div class="container-fluid">
            <div class="row align-items-center ">
              <div class="col-xl-2 col-lg-3 col-md-3">
                <div class="logo">
                  <a href="./index.html">
                    <img src="/img/nav_logo_2.png" alt="">
                  </a>
                </div>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-7">
                <div class="nav-title">
              One-Stop Solar Products Integrator
            </div>
              </div>
              <div class="col-md-2 head-btn align-center">
                <div class="head-right">
              <div class="head-cart">
                <i id="shoppingCart" class="iconfont icon-gouwucheman"></i>
              </div>
              <div class="header-button">
                <a href="./contactForm.html">Get A Quote</a>
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="original-box d-lg-block d-none">
      <div class="top-nav-menu">
        <el-menu background-color="#fff" text-color="#333" :default-active="activeIndex" class="el-menu-demo"
          mode="horizontal" router>
          <template v-for="(item, index) in navItems">
            <el-menu-item :key="index" :index="item.path" v-if="!item.children" class="no-select">
              {{ item.name }}
            </el-menu-item>
            <el-submenu :key="index" :index="item.id" v-else @mouseenter="handleOpen(item.id)"
              @mouseleave="handleClose(item.id)">
              <template slot="title">
                <router-link :to="item.path" class="submenu-title no-select">
                  <span>{{ item.name }}</span>
                  <!-- <i class="el-icon-arrow-down" :class="{ 'is-opened': isOpen[item.id] }"></i> -->
                </router-link>
              </template>
              <el-menu-item v-for="(child, childIndex) in item.children" :key="childIndex" :index="child.path"
                class="no-select">
                {{ child.name }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
    </div>
    <!--自适应导航栏-->
    <div class="responsive-box d-lg-none">
    <div class="pos-f-t">
      <nav class="navbar navbar-dark">
        <div class="nav-titles">
          <a href="./index.html" style="color: white;">Easy Solar Supply</a>
        </div>
        <button class="navbar-toggler" type="button" @click="toggleNav" data-toggle="collapse" data-target="#mobileNavbar"
          aria-controls="mobileNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon custom-toggler-icon"></span>
        </button>
      </nav>
      <div class="collapse" id="mobileNavbar" v-if="isNavOpen">
  <div class="mobile-head">
    <ul class="nav_scroll">
      <li v-for="item in navItems" :key="item.id">
        <a :href="item.path">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</div>
    </div>
  </div>
  </div>
</template>
<!-- 引入样式 -->
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" />
<link rel="stylesheet" href="https://unpkg.com/element-ui/lib/theme-chalk/index.css" />
<link rel="stylesheet" href="//at.alicdn.com/t/c/font_4719356_3ikvpozcz7l.css" />
<!-- 引入组件库 -->
<script src="https://unpkg.com/element-ui/lib/index.js"></script>
<script src="//at.alicdn.com/t/c/font_4719356_3ikvpozcz7l.js"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
<script>
export default {
  name: 'HeadersList',
  data() {
    return {
      isNavOpen: false,
      activeIndex: '1',
      navItems: [
        { id: '1', name: 'Residential', path: '/residential' },
        { id: '2', name: 'Commercial', path: '/commercial' },
        { id: '3', name: 'Products', path: '/products' },
        { id: '4', name: 'How to Purchase', path: '/purchase' },
        { id: '6', name: 'Ecosystem', path: '/ecosystem' }, 
        {id: '7', name: 'About Us',  path: '/aboutUs'}
      ],
      isDropdownOpen: false,
      isOpen: {},
      user:''
    };
  },
  computed: {
    lastItem() {
      return this.navItems[this.navItems.length - 1];
    }
  },
  methods: {
    toggleNav() {
        this.isNavOpen = !this.isNavOpen;
        console.log('isNavOpen:', this.isNavOpen); // 用于调试
      },
    handleOpen(id) {
      this.isOpen[id] = true;
    },
    handleClose(id) {
      this.isOpen[id] = false;
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 35) {
        // 滚动超过 35px 时显示导航栏
        document.querySelector('.top-navList').classList.add('fixed');
      } else {
        // 滚动到 35px 以内时隐藏导航栏
        document.querySelector('.top-navList').classList.remove('fixed');
      }
    },
    getUser() {
      // const token = localStorage.getItem('token');
      // if (token) {
      //   this.user = token; // 假设 token 就是用户信息，实际应用中可能需要从后端获取用户信息
      // } else {
      //   this.user = '';
      // }
    }
  },
  // 在组件的 methods 或生命周期钩子中 
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.user = localStorage.getItem('user')
    console.log(this.user)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style scoped>
li {
  list-style: none;
}
ul{
  margin: 0 !important;
}
a{
  color: #333;
  text-decoration: none;
}
.top-header {
  padding: 0.1rem 0;
  background: #F8F8F8;
  border-bottom: 1px solid #E5E5E5;
}
.top-header .top-header-user ul {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.top-header .top-header-user ul li{
  margin: 0 15px;
  font-size: 13px;
}
.navbar-dark{
  padding: 10px;
}
.top-header-user {
  line-height: 40px;
}
.top-header-users {
  display: flex;
  justify-content: flex-end;
}
.top-header-user {
  display: flex;
}
.top-navList,
.responsive-box {
  background: #7EB63B;
}
/* 确保没有其他 CSS 规则覆盖 v-show 的 display 样式 */
.collapse {
  display: none !important; /* 默认隐藏 */
}

.collapse.v-show {
  display: block !important; /* 显示时的样式 */
}
.collapse {
  display: block !important; /* 确保没有设置 display: none; */
}
.mobile-head{
  width: 100%;
  background: white;
}
.nav_scroll{
  width: 100%;
  overflow: auto;
}
.nav_scroll li{
  padding: 1rem 2rem;
  border-bottom: 1px solid #E5E5E5;
  color: #333 !important;
  
}
.header-area .top-nav .nav-title {
    font-size: 1.75rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 5.25rem;
}

.header-button {
    display: inline-block;
}
.header-area .top-nav #shoppingCart {
    cursor: pointer;
}
#shoppingCart{
  font-size: 30px;
  color: white;
}
.head-right{
  display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}
.header-area .top-nav .head-right i {
    font-size: 1.5rem;
    position: relative;
    color: #FFFFFF;
}
.header-button a {
    background: #7EB63B;
    color: #fff;
    display: inline-block;
    padding: 12px 20px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    border: 1px solid #fff;
}
.header-button a{
  color:  white !important;
}
.logo{
  display: flex;
  justify-content: flex-end;
}
.footer-section {
@media only screen and (min-width: 480px) and (max-width: 599px) {
  
        text-align: center;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section {
        text-align: center;
    }
}
 .footer-widget-desc p {
@media only screen and (min-width: 480px) and (max-width: 599px) {
   
        margin: auto;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section .solar-conatct-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area p {
    opacity: 0.8;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 25px;
}
 .footer-section {
@media only screen and (min-width: 480px) and (max-width: 599px) {
  
        text-align: center;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section {
        text-align: center;
    }
}
 .footer-widget-desc p {
@media only screen and (min-width: 480px) and (max-width: 599px) {
   
        margin: auto;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section .solar-conatct-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area p {
    opacity: 0.8;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 25px;
}
.footer-section {
@media only screen and (min-width: 480px) and (max-width: 599px) {
  
        text-align: center;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section {
        text-align: center;
    }
}
 .footer-widget-desc p {
@media only screen and (min-width: 480px) and (max-width: 599px) {
   
        margin: auto;
    }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
    .footer-section .solar-conatct-box {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area {
    border-top: 1px solid #3A4034;
    margin-top: 99px;
}
.copyright-area p {
    opacity: 0.8;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    margin-top: 25px;
}
</style>
