import { render, staticRenderFns } from "./HeadersList.vue?vue&type=template&id=4841a264&scoped=true"
import script from "./HeadersList.vue?vue&type=script&lang=js"
export * from "./HeadersList.vue?vue&type=script&lang=js"
import style0 from "./HeadersList.vue?vue&type=style&index=0&id=4841a264&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4841a264",
  null
  
)

/* custom blocks */
import block0 from "./HeadersList.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Fcdn.jsdelivr.net%2Fnpm%2Fbootstrap%405.3.0%2Fdist%2Fcss%2Fbootstrap.min.css&rel=stylesheet"
if (typeof block0 === 'function') block0(component)
import block1 from "./HeadersList.vue?vue&type=custom&index=1&blockType=link&rel=stylesheet&href=https%3A%2F%2Funpkg.com%2Felement-ui%2Flib%2Ftheme-chalk%2Findex.css"
if (typeof block1 === 'function') block1(component)
import block2 from "./HeadersList.vue?vue&type=custom&index=2&blockType=link&rel=stylesheet&href=%2F%2Fat.alicdn.com%2Ft%2Fc%2Ffont_4719356_3ikvpozcz7l.css"
if (typeof block2 === 'function') block2(component)

export default component.exports