import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'index',
    // component: HomeView
    component:()=>import('../views/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
      path:'/headersList',
      name:'headersList',
      component:()=>import('../views/HeadersList.vue')
  },
  // ================================================= 
  {
    path: '/residential',
    name: 'residential',
    component: () => import('../views/Residential.vue')
  },
  {
      path:'/commercial',
      name:'commercial',
      component:()=>import('../views/Commercial.vue')
  },
  {
      path:'/products',
      name:'products',
      component:()=>import('../views/Products.vue')
  },
  {
      path:'/purchase',
      name:'purchase',
      component:()=>import('../views/Purchase.vue')
  },
  {
      path:'/service',
      name:'service',
      component:()=>import('../views/Service.vue')
  },
  {
      path:'/ecosystem',
      name:'ecosystem',
      component:()=>import('../views/Ecosystem.vue')
  },
  {
      path:'/aboutUs',
      name:'aboutUs',
      component:()=>import('../views/AboutUs.vue')
  },
  {
      path:'/productDetails',
      name:'productDetails',
      component:()=>import('../views/ProductDetails.vue')
  },
  {
    path: '/ProductCart',
    name: 'ProductCart',
    component:()=>import('../views/ProductCart.vue')
  },
  {
    path: '/login',
    name: 'login',
    component:()=>import('../views/Login.vue')
  },
  {
    path: '/test',
    name: 'test',
    component:()=>import('../views/test.vue')
  },
  {
    path: '/regiser',
    name: 'regiser',
    component:()=>import('../views/regiser.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component:()=>import('../views/Dashboard.vue')
  },{
    path: '/orders',
    name: 'orders',
    component:()=>import('../views/components/Orders.vue')
  },{
    path: '/designOrder',
    name: 'designOrder',
    component:()=>import('../views/components/DesignOrder.vue')
  },{
    path: '/cart',
    name: 'cart',
    component:()=>import('../views/components/Cart.vue')
  }
  ,{
    path: '/pay',
    name: 'pay',
    component:()=>import('../views/Pay.vue')
  },{
    path: '/residentialDetails',
    name: 'residentialDetails',
    component:()=>import('../views/ResidentialDetails.vue')
  }
]
const router = new VueRouter({
  routes,
  mode: 'history', // 设置模式为 'history'  
})
export default router