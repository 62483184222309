// src/mixins/globalMixin.js
import HeadersList from '../views/HeadersList.vue';

console.log(HeadersList); // 检查 HeadersList 是否正确导入

export default {
  components: {
    HeadersList
  },
  methods: {
    // 可以在这里定义一些全局方法
    sayHello() {
      alert('Hello from global mixin!');
    }
  }
};