// src/main.js
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'; // 引入路由配置
import "/public/css/index.css"
import "/public/css/iconfont.css"
import globalMixin from './mixins/globalMixin';// 引入Mixins
import store from './store';
import "/public/css/oppoSans.css"
import '../public/bootstrap-5.0.2-dist/css/bootstrap.min.css';
import 'bootstrap';
import 'jquery/dist/jquery.min.js';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
import CKEditor from '@ckeditor/ckeditor5-vue2';
window.toastr = toastr;
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAsjDyAQFKHrRgU1ceGWNVTXjNdMZsv7ts', // 替换为你的 Google Maps API 密钥
    libraries: 'places,geometry'
  }
});
// 注册全局 mixin
Vue.mixin(globalMixin);
Vue.use(ElementUI);
Vue.use( CKEditor );
new Vue({
  el: '#app',
  router, // 使用路由
  store,// 使用vuex
  render: h => h(App),
}).$mount('#app');