import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    selectedProduct: sessionStorage.getItem('product')?JSON.parse(sessionStorage.getItem('product')):null,
    cart: sessionStorage.getItem('cart')?JSON.parse(sessionStorage.getItem('cart')):[], // 新增购物车状态
    user:null,
    userName:sessionStorage.getItem('userName')

  },
  mutations: {
    setSelectedProduct(state, product) {
		//缓存
		sessionStorage.setItem('product',JSON.stringify(product))
      state.selectedProduct = product;
    },
    addToCart(state, product) {
      const existingProduct = state.cart.find(item => item.uuid === product.uuid);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        product.quantity=1
        state.cart.push({ ...product});
      }
	  	console.log(state.cart,"state.cart")
	  sessionStorage.setItem('cart',JSON.stringify(state.cart))
    },
    setUser(state, user) {
      state.user = user;
    },
    setError(state, error) {
      state.error = error;
    },
  }, 
  actions: {
    async fetchProductDetails({ commit }, productId) {
		console.log(productId,"productId")
      try {
        const response = await fetch(`/api/products/${productId}`);
        const data = await response.json();s
        commit('setSelectedProduct', data);
        commit('setError', null); // 清除之前的错误
      } catch (error) {
        console.error('Error fetching product details:', error);
        commit('setError', error.message);
      }
    },
    addProductToCart({ commit }, product) {
      commit('addToCart', product);
    },
    // ===========登录
    login({ commit }, { username, password }) {
      // 自定义数据验证
      const validUsers = [
        { username: 'admin', password: '123456' },
        { username: 'user', password: 'password' },
      ];
      const user = validUsers.find(u => u.username === username && u.password === password);
      if (user) {
        commit('setUser', user);
      } else {
        commit('setUser', null);
      }
    },
  },
  getters: {
    getCartItems: state => state.cart,
    isLoggedIn: state => !!state.user,
  }
});
// async fetchProductDetails({ commit }, productId) {
//   try {
//     // 调用实际的 API
//     const response = await axios.get(`https://api.example.com/products/${productId}`);
//     commit('setSelectedProduct', response.data);
//   } catch (error) {
//     console.error('Error fetching product details:', error);
//   }
// }